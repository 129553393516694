// @@@ This is in the CssConfig.scss file because the Sass variable does not work for some reason.
// :root {
//   --mdc-theme-primary: $text-colour;
// }

button.mdc-tab {
  font-family: unset;
  font-weight: 700;
}

.tab-menu {
  background: $bar-colour;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.tabMenuLink {
  color: $bar-text-colour;
  text-decoration: none;
}
