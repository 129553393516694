.story-item {
  margin-top: 20px;
}

.first-story-item {
  margin-top: unset !important;
}

.story-image {
  width: 100%;
}

.story-yt {
  width: 100%;

  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.story-yt iframe,
.story-yt object,
.story-yt embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: $mobile-break-width) {
  .story-text {
    margin-left: 20px;
    margin-right: 20px;
  }
}
