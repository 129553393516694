$menu-item-background-colour: #444444;
$menu-item-colour: #ffffff;
$menu-item-hover-colour: $text-hover-colour;

.top-bar {
  height: 66px;
  position: fixed;
  z-index: 5;
  top: 0px;
  right: 0px;
  left: 0px;
  background: $bar-colour;
}

#top-bar-header {
  color: $bar-text-colour;
  margin: 0px 15px;
}

.top-bar-content {
  position: absolute;
  top: 50%;
  left: 72px;
  right: 66px;
  height: 66px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.bm-phone-button {
  z-index: 1000;
  position: fixed;
  top: 17px;
  right: 17px;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 18px;
  top: 18px;
}

.bm-burger-bars {
  background: $bar-text-colour;
  opacity: 1;
}

.bm-burger-bars-hover {
  background: $bar-text-colour;
  opacity: 1;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: $menu-item-colour;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-menu {
  background: $menu-item-background-colour;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: $bar-colour;
}

.bm-item-list {
  color: $menu-item-colour;
}

.bm-item {
  display: inline-block;
  padding: 0.5em;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-item {
  color: $menu-item-colour;
}

.menu-item:hover {
  color: $menu-item-hover-colour;
}
