.sectionTitle {
  font-size: 150%;
  font-weight: 700;
  margin-top: 10px;
}

.menuItem {
  margin-top: 5px;
  margin-left: 20px;
}

.itemChoice {
  margin-left: 40px;
}
