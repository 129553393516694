
@font-face {
  font-family: 'Proza Libre';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/prozalibre/v5/LYjGdGHgj0k1DIQRyUEyyEoodN8.ttf) format('truetype');
}

body {
    font-family: 'Proza Libre', sans-serif;
    font-size: 100%;
}
