@import "./CssConfig.scss";

@import "./components/utils/BackgroundImage.scss";
@import "./components/utils/CenterOverlay.scss";
@import "./components/Menus/Menu.scss";
@import "./components/Story/Story.scss";
@import "./components/Video.scss";
@import "./components/Pages/NavigationBar.scss";
@import "./components/Pages/BurgerMenu.scss";

body {
  color: $text-colour;
}

img {
  display: block;
}

iframe {
  display: block;
}

.navLink {
  margin: 10px;
}

.header {
  font-size: 150%;
  font-weight: 700;
  text-align: center;
  color: $text-colour;
  text-decoration: none;
}

.centred {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.centredText {
  text-align: center;
}

.italics {
  font-style: italic;
}

.right {
  float: right;
}

.underline {
  border-bottom: 1px dotted $text-colour;
}

.underline:hover {
  border-bottom: 1px solid $text-colour;
}

.today {
  font-weight: 700;
}

.marginTop {
  margin: 20px 0px 0px 0px;
}

.marginBottom {
  margin: 0px 0px 20px 0px;
}

.pdfobject-container {
  height: calc(100vh - 186px); /* @@@ Need this to be better configured */
}

.phone-icon {
  color: $bar-text-colour;
}

.phone-number {
  font-size: 150%;
  margin-bottom: 10px;
}

.disable-scroll {
  height: 100%;
  overflow: hidden;
}

.initial-overlay-text {
  padding: 20px;
  background: $transparent-overlay-colour;
  width: fit-content;
}

.io-skip-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: $bar-text-colour;
  background-color: $bar-colour;
  padding: 20px;
  border-radius: 36px;
  cursor: pointer;
  z-index: 1002;
}

.io-skip-container:hover {
  color: $text-hover-colour;
}

@media only screen and (min-width: $mobile-break-width) {
  .io-skip-container {
    bottom: 40px;
    right: 40px;
  }
}
