.centerOverlayOuter {
  width: 100%;
}

.centerOverlayInner {
  margin: auto;
  max-width: 600px;
}

.transparentBackground {
  background: $transparent-overlay-colour;
}

@media only screen and (min-width: $mobile-break-width) {
  .centerOverlayMiddle {
    padding: 40px;
  }
}
