$text-colour: #000000;
$text-hover-colour: #ffcc22;
$transparent-overlay-colour: rgba(255, 255, 255, 0.85);

$mobile-break-width: 750px;

// Navigation bar config
$bar-text-colour: #000000;
$bar-colour: #ffffff;

// This should change depending on the NavigationType.
@media only screen and (max-width: $mobile-break-width) {
  body {
    margin-top: 66px; // Use NavigationType 3 when screen is a certain width.
  }
}
@media only screen and (min-width: $mobile-break-width) {
  body {
    // margin-top: XXpx; // 1
    // margin-top: 66px; // 2
    margin-top: 48px; // 3
  }
}

// @@@ This should use the $bar-text-colour variable and be in the NavigationBar.scss file, but the variable does not work
// for some reason.
:root {
  --mdc-theme-primary: #000000;
}
