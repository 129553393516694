@mixin full-screen-image($z-index) {
  content: "";
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
  z-index: $z-index;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-image: url(/bg.jpg);
}

// This sets the background image for all pages.
body:after {
  @include full-screen-image(-1);
}

.initial-overlay {
  @include full-screen-image(100);
  cursor: pointer;
}
